<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 tabvertical">
      <c-tab
        type="vertical"
        :tabItems="tabItems"
        :height.sync="tabheight"
        v-model="tab"
      >
        <template v-slot:default="tab">
          <component
            :is="tab.component"
            :editable="editable"
            :param="param"
          />
        </template>
      </c-tab>
    </div>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'equipment-etc',
  props: {
    param: {
      type: Object,
      default: () => ({
        equipmentCd: '',
        equipmentNo: '',
        plantName: '',
        plantCd: '',
        processCds: [],
        equipmentTypeName: '',
        equipmentName: '',
        equipmentTypeCd: '',
      }),
    },
    editable: {
      type: Boolean,
      default: true,
    },
    revEquipmentCd: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      data: {
        equipmentCd: '',
        equipmentNo: '',
        plantName: '',
        plantCd: '',
        processCds: [],
        equipmentTypeName: '',
      equipmentName: '',
        equipmentTypeCd: '',
      },
      tabheight: '',
      tab: 'equipmentBom',
      tabItems: [
        // { name: 'equipmentMaterial', icon: 'miscellaneous_services', label: 'Spare Part', component: () => import(`${'./equipmentMaterial.vue'}`) },
        { name: 'equipmentBom', icon: 'location_city', label: '설비 BOM', component: () => import(`${'./equipmentBom.vue'}`) },
        { name: 'equipmentDrawing', icon: 'filter', label: '관련 도면', component: () => import(`${'./equipmentDrawing.vue'}`) },
      ],
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
    'revEquipmentCd'() {
      this.getDetail();
    },
  },
  methods: {
    init() {
      this.tabheight = (window.innerHeight - 200) + 'px';
      this.getDetail();
    },
    getDetail() {
      if (this.param.equipmentCd) {
        this.$http.url = selectConfig.mdm.equipment.get.url;
        this.$http.param = {
          equipmentCd: this.param.isBom || this.revEquipmentCd === this.param.equipmentCd ? this.param.equipmentCd : this.revEquipmentCd, 
          plantCd: this.param.plantCd
        }
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.data = _result.data;
          this.param.plantName = _result.data.plantName;
          this.param.plantCd = _result.data.plantCd;
          // this.param.equipmentCd = _result.data.equipmentCd;
          this.param.equipmentNo = _result.data.equipmentNo;
          this.param.equipmentTypeCd = _result.data.equipmentTypeCd;
          this.param.equipmentTypeName = _result.data.equipmentTypeName;
          this.param.equipmentName = _result.data.equipmentName;
          this.param.processCds = _result.data.processCds;
        },);
      }
    },
  }
};
</script>